import React from 'react';

// Define the structure of each item in the array
interface DataItem {
    label: string;
    data: number[];
}

interface DataTotalBoxProps {
    dataArray: DataItem[];
}

const DataTotalBox: React.FC<DataTotalBoxProps> = ({ dataArray }) => {
    // Function to calculate the sum of the data array
    const calculateSum = (data: number[]) => {
        return data.reduce((acc, val) => acc + val, 0);
    };

    return (
        <div className="data-box-container">
            {dataArray.map((item, index) => (
                <div key={index} className="data-box">
                    {/* Display the label */}
                    <p>{item.label}</p>
                    {/* Calculate and display the sum of the data */}
                    <h3>{calculateSum(item.data)}</h3>
                </div>
            ))}
        </div>
    );
};

export default DataTotalBox;
