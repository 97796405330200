import { DateRangePicker } from "rsuite";
import BarChartWithAnimation from "../../components/BarChartWithAnimation/BarChartWithAnimation";
import Dropdown from "../../components/Dropdown/Dropdown";
import { capitalize } from "../../services/baseServices";
import { useMemo, useState } from "react";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { useStatisticsFetch, useTransformData } from "../../hooks/statisticsHooks";
import Select from "react-select";
import { selectStyles } from "../../components/StyledComponents/SelectStyles";
import StatisticsTable from "./components/StatisticsTable";

interface StatisticsInstanceProps {
    statisticsType: string;
}

const StatisticsInstance: React.FC<StatisticsInstanceProps> = ({ statisticsType }) => {
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [selectedDataByType, setSelectedDataByType] = useState<'price' | 'quantity'>('price');
    const [selectedStatsType, setSelectedStatsType] = useState<'Table' | 'Bar Graph'>('Table');
    const [selectedLastYears, setSelectedLastYears] = useState<number>(2);

    const { responseData, yearlyResponseData, chartLabels, isLoading } = useStatisticsFetch(statisticsType, selectedYear, selectedMonth + 1, dateRange, selectedLastYears);
    const chartData = useTransformData(responseData, selectedDataByType);

    // const yearChartData = [];

    const monthsList = useMemo(() => ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'Sept', 'Oct', 'Nov', 'Dec'], []);
    const yearsList = useMemo(() => Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i), []);

    const yearChartData = useMemo(() => {
        return yearlyResponseData.map((yearData) => {
            if (!yearData.stats) return null;

            const membershipsData = yearData.stats.map(item => item.memberships[selectedDataByType]);
            const merchandisesData = yearData.stats.map(item => item.merchandises[selectedDataByType]);
            const privateClassesData = yearData.stats.map(item => item.privateClasses[selectedDataByType]);
            const totalData = yearData.stats.map(item => item.memberships[selectedDataByType] + item.merchandises[selectedDataByType] + item.privateClasses[selectedDataByType]);

            return {
                year: yearData.year,
                stats: [
                    { label: 'Memberships', data: membershipsData },
                    { label: 'Merchandise', data: merchandisesData },
                    { label: 'PrivateClasses', data: privateClassesData },
                    { label: 'Total', data: totalData }
                ]
            };
        }); // Filter out any null results
    }, [yearlyResponseData, selectedDataByType]);

    const yearTotalWiseChartData = useMemo(() => {

        let membershipsData: number[] = [];
        let merchandisesData: number[] = [];
        let privateClassesData: number[] = [];
        let totalData: number[] = [];

        yearlyResponseData.forEach((yearData) => {
            // Initialize data accumulators

            let tempMembershipsData = 0;
            let tempMerchandisesData = 0;
            let tempPrivateClassesData = 0;
            let tempTotalData = 0;
            // Accumulate data for each stat
            yearData.stats.forEach((item) => {
                tempMembershipsData += item.memberships[selectedDataByType];
                tempMerchandisesData += item.merchandises[selectedDataByType];
                tempPrivateClassesData += item.privateClasses[selectedDataByType];
                tempTotalData += item.memberships[selectedDataByType] +
                    item.merchandises[selectedDataByType] +
                    item.privateClasses[selectedDataByType];
            });

            membershipsData.push(tempMembershipsData);
            merchandisesData.push(tempMerchandisesData);
            privateClassesData.push(tempPrivateClassesData);
            totalData.push(tempTotalData);
        });

        return [
            { label: 'Memberships', data: membershipsData },
            { label: 'Merchandise', data: merchandisesData },
            { label: 'PrivateClasses', data: privateClassesData },
            { label: 'Total', data: totalData }
        ];

    }, [yearlyResponseData, selectedDataByType]);

    // console.log('yearResponseData: ', yearlyResponseData)
    // console.log('yearlyChartData: ', yearChartData)
    // console.log('yearlyTotalWiseChartData: ', yearTotalWiseChartData)

    const alternateStatsData = [
        { label: 'Memberships', data: [0] },
        { label: 'Merchandise', data: [0] },
        { label: 'PrivateClasses', data: [0] },
        { label: 'Total', data: [0] }
    ]

    return (
        <div className="stats-instance-container">
            <div className="d-flex justify-content-center">
                <Dropdown label="Select Data By Type" options={['Price', 'Quantity']} selectedValue={capitalize(selectedDataByType)} onChange={(val) => setSelectedDataByType(val === 'Price' ? 'price' : 'quantity')} />
                <Dropdown label="Select Stats Type" options={['Table', 'Bar Graph']} selectedValue={capitalize(selectedStatsType)} onChange={(val) => setSelectedStatsType(val)} />
            </div>

            <div className="d-flex justify-content-center mx-auto">
                {(statisticsType === 'weekly' || statisticsType === 'daily') && (
                    <>
                        <div className="select-wrapper me-2">
                            <label>Select Month</label>
                            <Select
                                placeholder={`Select Month`}
                                options={monthsList.map((opt, index) => ({ value: index, label: opt.toString() }))}
                                value={{ label: monthsList[selectedMonth], value: selectedMonth }}
                                onChange={(selectedOption) => setSelectedMonth(selectedOption?.value as number)}
                                className="my-2"
                                styles={selectStyles}
                            />
                        </div>
                        <Dropdown label="Select Year" options={yearsList} selectedValue={selectedYear} onChange={setSelectedYear} />
                    </>
                )}
                {statisticsType === 'monthly' && (
                    <Dropdown label="Select Year" options={yearsList} selectedValue={selectedYear} onChange={setSelectedYear} />
                )}
                {statisticsType === 'yearly' && (
                    <span>
                        Show stats of last
                        <select
                            value={selectedLastYears}
                            onChange={(e) => setSelectedLastYears(parseInt(e.target.value))}
                            className="mx-2 px-2 py-1 mb-4"
                            style={{ borderRadius: 6 }}
                        >
                            {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        years
                    </span>
                )}
                {/* {statisticsType === 'date-range' && (
                    <DateRangePicker size="md" placeholder="Select Date Range" onChange={setDateRange} />
                )} */}
            </div>

            {selectedStatsType === 'Bar Graph' && statisticsType !== 'yearly' && (
                <div className="chart-container">
                    <BarChartWithAnimation labels={chartLabels} chartData={chartData} />
                </div>
            )}

            {selectedStatsType === 'Table' && statisticsType !== 'yearly' && (
                <StatisticsTable statisticsType={statisticsType} selectedMonth={monthsList[selectedMonth]} selectedYear={selectedYear.toString()} chartLabels={chartLabels} chartData={chartData} />
            )}

            {selectedStatsType === 'Bar Graph' && statisticsType === 'yearly' && (
                <>
                    <div className="d-flex flex-wrap justify-content-center yearly-main-chart-container"
                        style={{ width: 'fit-content', margin: 'auto' }}>
                        <div className="yearly-chart-container" style={{ width: 'fit-content', minWidth: '75%' }}>
                            <BarChartWithAnimation
                                labels={yearChartData.map(data => { return (data ? data.year.toString() : '0') })}
                                chartData={yearTotalWiseChartData} />
                        </div>
                    </div>

                    <h3 className="my-4 text-center" style={{ fontWeight: 700 }}>Detailed Yearly Stats</h3>
                    <div className="d-flex flex-wrap justify-content-center yearly-main-chart-container">
                        {yearChartData.map((yearStats) => {
                            return (
                                <div className="yearly-chart-container">
                                    <BarChartWithAnimation
                                        key={yearStats?.year}
                                        labels={chartLabels}
                                        chartData={yearStats?.stats || []} />
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
            {selectedStatsType === 'Table' && statisticsType === 'yearly' && (
                //             yearlyResponseData.map((yearStats) => (
                //                  const yearChartData = useTransformData(responseData, selectedDataByType);
                //                 <StatisticsTable statisticsType={statisticsType} selectedMonth={yearStats.year.toString()} selectedYear={yearStats.year.toString()} chartLabels={chartLabels} chartData={yearStats.stats} />
                //             ))
                <>
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="statistics-table-container" style={{
                            width: 'fit-content',
                            height: 'fit-content',
                            margin: 'auto'
                        }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={5}>Yearly Stats Data</th>
                                    </tr>
                                    <tr>
                                        <th>Label</th>
                                        {/* Loop through the first element's stats to generate the header */}
                                        {yearChartData[0]?.stats.map((dataItem, index) => (
                                            <th key={index}>{dataItem?.label}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Loop through yearChartData to generate the rows */}
                                    {yearChartData.map((yearData, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {/* Display the year */}
                                            <td>{yearData?.year}</td>
                                            {/* Loop through the stats to calculate and display the sum */}
                                            {yearData?.stats.map((dataItem, colIndex) => (
                                                <td key={colIndex}>
                                                    {dataItem?.data.reduce((acc, cur) => acc + cur, 0)}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                                {/* Optional: Uncomment and handle footer for total sums if needed */}
                                {/* <tfoot>
                                <tr>
                                    <th>Total</th>
                                    {totals.map((total, index) => (
                                      <th key={index}>{total}</th>
                                    ))}
                                </tr>
                             </tfoot> */}
                            </table>
                        </div>
                    </div>

                    <h3 className="my-4 text-center" style={{ fontWeight: 700 }}>Detailed Yearly Stats</h3>
                    <div className="d-flex flex-wrap justify-content-center">
                        {yearChartData.map((yearStats) => {
                            return (
                                <StatisticsTable
                                    key={yearStats?.year} // Add a unique key for each item in the map
                                    statisticsType={statisticsType}
                                    selectedMonth={yearStats?.year.toString() || ''}
                                    selectedYear={yearStats?.year.toString() || ''}
                                    chartLabels={chartLabels}
                                    chartData={yearStats?.stats || []} // Pass transformed data here
                                />
                            );
                        })}
                    </div>
                </>

            )}

            {isLoading && <p>Loading...</p>}
        </div>
    );
};

export default StatisticsInstance;
