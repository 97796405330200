import { duration } from "@mui/material";
import { FROTA_BACKEND_URL } from "../config";
import { MartialArt, MartialArtDetails } from "../config/types";
import { createHttpService } from "./httpService";

export const handleFetchMartialArts = async () => {
    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.get<MartialArt[]>(`/martialArts`);

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'handleFetchMartialArts(): Unknown error');
        }

        return result.data;
    } catch (error) {
        throw new Error(
            `handleFetchMartialArts(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}


export const createMartialArt = async (martialArt: MartialArtDetails) => {

    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.post(`/martialArts`, {
            name: martialArt.name,
            price: martialArt.price,
            duration: martialArt.duration,
        });

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'createMartialArt(): Unknown error');
        }

        return result;
    } catch (error) {
        throw new Error(
            `createMartialArt(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

export const updateMartialArt = async (martialArt: MartialArtDetails) => {

    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.put(`/martialArts/${martialArt.id}`, {
            name: martialArt.name,
            price: martialArt.price,
            duration: martialArt.duration,
        });

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'updateMartialArt(): Unknown error');
        }

        return result;
    } catch (error) {
        throw new Error(
            `updateMartialArt(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

export const deleteMartialArt = async (martialArtId: number) => {

    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.deleteMethod(`/martialArts/${martialArtId}`);

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'deleteMartialArt(): Unknown error');
        }

        return result;
    } catch (error) {
        throw new Error(
            `deleteMartialArt(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

