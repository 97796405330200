import React from 'react';
import { Link } from 'react-router-dom';

interface AdminNavLinksProps {
    onClick: () => void;
}

const AdminNavLinks: React.FC<AdminNavLinksProps> = ({ onClick }) => {
    return (
        <>
            <li className="nav-item" onClick={onClick}>
                <Link to={'/home'} className="nav-link">DASHBOARD</Link>
            </li>
            <li className="nav-item" onClick={onClick}>
                <Link to={'/stats'} className="nav-link">STATISTICS</Link>
            </li>
            <li className="nav-item" onClick={onClick}>
                <Link to={'/management'} className="nav-link">MANAGEMENT</Link>
            </li>
            <li className="nav-item" onClick={onClick}>
                <Link to={'/admin-login'} className="nav-link" onClick={() => {
                    localStorage.removeItem('frotaToken');
                    localStorage.removeItem('refreshToken');
                    sessionStorage.removeItem('user');
                }}>LOGOUT</Link>
            </li>
            {/* <li className="nav-item" onClick={onClick}>
                <p className="nav-link" style={{
                    margin: 0,
                    borderRadius: '50px',
                    background: '#ffeeee'
                }}>CREDITS: 0</p>
            </li> */}
        </>
    );
};

export default AdminNavLinks;
