// MerchandiseReport.tsx
import React, { useState } from 'react';
import MerchandiseModal from '../Modals/MerchandiseModal';
import { Merchandise } from '../../config/types';


interface MerchandiseReportProps {
    merchandises: Merchandise[];
    setMerchandises: (mechandise: Merchandise[]) => void;
    isMerchandisesUpdated: boolean;
    setIsMerchandisesUpdated: (value:boolean) => void
}

interface CurrentMerchandise extends Merchandise {
    id: number;
}


const MerchandiseReport: React.FC<MerchandiseReportProps> = ({ merchandises, setMerchandises, isMerchandisesUpdated, setIsMerchandisesUpdated }) => {

    const [merchandiseModalIsOpen, setMerchandiseModalIsOpen] = useState(false);
    const [isEditingMerchandise, setIsEditingMerchandise] = useState(false);
    const [currentMerchandise, setCurrentMerchandise] = useState<CurrentMerchandise | null>(null);

    const openMerchandiseModal = () => setMerchandiseModalIsOpen(true);
    const closeMerchandiseModal = () => {
        setMerchandiseModalIsOpen(false);
        setCurrentMerchandise(null);
        setIsEditingMerchandise(false);
    };

    const handleAddNewMerchandise = () => {
        setCurrentMerchandise({
            id: 0,
            itemId: merchandises && merchandises.length > 0
                ? merchandises[merchandises.length - 1].itemId + 1
                : 1,
            item: '',
            pricePerItem: 0,
            price: 0,
            quantity: 1
        });
        setIsEditingMerchandise(false);
        openMerchandiseModal();
    };

    const handleEditMerchandise = (merch: CurrentMerchandise) => {
        setCurrentMerchandise(merch);
        setIsEditingMerchandise(true);
        openMerchandiseModal();
    };

    const handleSaveMerchandise = (merch: CurrentMerchandise) => {
        if (isEditingMerchandise) {
            const tempMerchandises = [...merchandises];
            tempMerchandises?.splice(merch.id, 1, merch)
            setMerchandises(tempMerchandises);
        } else {
            if (merchandises?.length > 0) {
                setMerchandises([...merchandises, merch]);
            } else {
                setMerchandises([merch]);
            }
        }
        setIsMerchandisesUpdated(!isMerchandisesUpdated)
        closeMerchandiseModal();
    };

    const handleDeleteMerchandise = (merchIndex?: number) => {
        if (currentMerchandise && (merchIndex || merchIndex === 0)) {
            const tempMerchandises = [...merchandises]
            tempMerchandises?.splice(merchIndex, 1) // removed element from Merchandises
            setMerchandises(tempMerchandises);
        }
        setIsMerchandisesUpdated(!isMerchandisesUpdated)
        closeMerchandiseModal();
    };

    return (
        <div className="section">
            <div className="section-header">
                <h3>MERCHANDISE</h3>
                <button className="add-btn" onClick={handleAddNewMerchandise}>ADD NEW</button>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>PRICE PER ITEM</th>
                        <th>SELLING PRICE</th>
                        <th>QUANTITY</th>
                    </tr>
                </thead>
                <tbody>
                    {merchandises?.map((merch, index) => (
                        <tr key={index} onClick={() => handleEditMerchandise({ ...merch, id: index })}>
                            <td>{merch.item}</td>
                            <td>${merch.pricePerItem}</td>
                            <td>${merch.price}</td>
                            <td>{merch.quantity}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3} className="total-label">TOTAL MERCHANDISE</td>
                        <td className="total-value">${merchandises?.length > 0 ? merchandises.reduce((acc, cur) => acc + (cur.price), 0) : 0}</td>
                    </tr>
                </tfoot>
            </table>

            <MerchandiseModal
                isOpen={merchandiseModalIsOpen}
                onRequestClose={closeMerchandiseModal}
                onSave={handleSaveMerchandise}
                onDelete={isEditingMerchandise ? handleDeleteMerchandise : undefined}
                merchandise={currentMerchandise}
                isEditing={isEditingMerchandise}
            />
        </div>
    );
};

export default MerchandiseReport;
