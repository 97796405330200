import { selectStyles } from "../StyledComponents/SelectStyles";
import Select from "react-select";

interface DropdownProps {
    label: string;
    options: (string | number)[];
    selectedValue: string | number;
    onChange: (value: any) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ label, options, selectedValue, onChange }) => {
    return (
        <div className="select-wrapper me-2">
            {label && (<label style={{ whiteSpace: 'nowrap' }}>{label}</label>)}
            <Select
                placeholder={`Select ${label}`}
                options={options.map((opt) => ({ value: opt, label: opt.toString() }))}
                value={{ label: selectedValue.toString(), value: selectedValue }}
                onChange={(selectedOption) => onChange(selectedOption?.value)}
                className="my-2"
                styles={selectStyles}
            />
        </div>
    );
};

export default Dropdown;
