import React from "react";

interface StatisticsTableProps {
  statisticsType: string;
  selectedMonth: string;
  selectedYear: string;
  chartLabels: string[];
  chartData: {
    label: string;
    data: number[];
  }[];
}

const StatisticsTable: React.FC<StatisticsTableProps> = ({ statisticsType, selectedMonth, selectedYear, chartLabels, chartData }) => {
  // Calculate the total for each data column (each 'label')
  const totals = chartData.map((dataItem) =>
    dataItem.data.reduce((sum, currentValue) => sum + currentValue, 0)
  );

  return (
    <div className="statistics-table-container">
      <table className="table">
        <thead>
          <tr>
            {(statisticsType === 'daily' || statisticsType === 'weekly') && (<th colSpan={5}>{selectedMonth + ' ' + selectedYear}</th>)}
            {(statisticsType === 'monthly' || statisticsType === 'yearly') && (<th colSpan={5}>{selectedYear}</th>)}
          </tr>
          <tr>
            <th>Label</th>
            {chartData.map((dataItem, index) => (
              <th key={index}>{dataItem.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {chartLabels.map((label, rowIndex) => (
            <tr key={rowIndex}>
              <td>{label}</td>
              {chartData.map((dataItem, colIndex) => (
                <td key={colIndex}>{dataItem.data[rowIndex]}</td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            {totals.map((total, index) => (
              <th key={index}>{total}</th>
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default StatisticsTable;
