import React, { useState } from 'react';
import Modal from 'react-modal';
import './modal.css'
import { MartialArt, MartialArtDetails, Membership } from '../../config/types';
import { selectStyles } from '../StyledComponents/SelectStyles';
import Select from 'react-select';
import { handleFetchMartialArts } from '../../services/martialArtServices';
import { toast } from 'react-toastify';

interface MembershipModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSave: (membership: CurrentMembership) => void;
    onDelete?: (index?: number) => void;
    membership: CurrentMembership | null;
    isEditing: boolean;
}

interface CurrentMembership extends Membership {
    id: number;
}

const MembershipModal: React.FC<MembershipModalProps> = ({
    isOpen,
    onRequestClose,
    onSave,
    onDelete,
    membership,
    isEditing
}) => {
    const [selectedItem, setSelectedItem] = useState({ value: '', label: '', price: 0, duration: '' });

    const [martialArtsList, setMartialArtsList] = useState<MartialArt[]>()
    const [martialArtDetailsList, setMartialArtDetailsList] = useState<MartialArtDetails[]>()
    const [formData, setFormData] = useState<CurrentMembership>({
        id: 0,
        martialArtId: membership?.martialArtId || 1,
        duration: membership?.duration || '',
        martialArt: membership?.martialArt || '',
        studentName: membership?.studentName || '',
        price: membership?.price || 0,
        paymentType: membership?.paymentType || '',
    });

    const getMartialArts = async () => {
        try {
            const response = await handleFetchMartialArts();
            setMartialArtsList(response);
        } catch (error) {
            console.error("Error: ", error)
        }
    }

    React.useEffect(() => {
        getMartialArts();
        if (membership) {
            setFormData(membership);
        }
    }, [membership]);

    const handleSelectItem = (selectedOption: any) => {
        setSelectedItem(selectedOption);


        setFormData({
            ...formData,
            martialArt: selectedOption.label,
            // duration: selectedOption.duration,
            // price: parseInt(selectedOption.price)
        })

        setMartialArtDetailsList(selectedOption.details);
    }

    const handleSelectDuration = (selectedOption: any) => {

        setFormData({
            ...formData,
            duration: selectedOption.label,
            price: parseInt(selectedOption.price)
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'price') {
            setFormData({ ...formData, price: parseInt(value) });
            return;
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.martialArt) {
            return toast.warn('Please Select a Martial Art.')
        }
        if (!formData.duration) {
            return toast.warn('Please enter a Duration.')
        }
        if (!formData.studentName) {
            return toast.warn('Please enter Student Name.')
        }
        if (!formData.paymentType) {
            return toast.warn('Please enter Payment Type.')
        }
        onSave(formData);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="AppointmentModal"
            overlayClassName="AppointmentModal-overlay"
            ariaHideApp={false}
            contentLabel={isEditing ? 'Edit Membership' : 'Add New Membership'}
        >
            <h2 className='text-center'>{isEditing ? 'Edit Membership' : 'Add New Membership'}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Martial Art(s):</label>
                    <Select
                        value={formData.martialArt ?
                            { label: formData.martialArt, value: formData.martialArtId }
                            : null}
                        placeholder="Select Martial Art"
                        onChange={handleSelectItem}
                        options={martialArtsList?.map(martialArt => ({
                            value: martialArt.id,
                            label: martialArt.name,
                            price: martialArt.price,
                            duration: martialArt.duration,
                            details: martialArt.details
                        }))}
                        className='my-2'
                        styles={selectStyles}
                    />
                </div>
                <div className='form-group-container'>
                    <div className="form-group">
                        <label>Duration:</label>
                        {/* <input
                            type="text"
                            name="duration"
                            value={formData.duration}
                            onChange={handleChange}
                        /> */}
                        <Select
                            value={formData.duration ?
                                { label: formData.duration, value: formData.duration }
                                : null}
                            placeholder="Select Duration"
                            onChange={handleSelectDuration}
                            options={martialArtDetailsList?.map(martialArt => ({
                                value: martialArt.duration,
                                label: martialArt.duration,
                                price: martialArt.price,
                            }))}
                            className='my-2'
                            styles={selectStyles}
                        />
                    </div>
                    <div className="form-group">
                        <label>Price:</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='form-group-container'>
                    <div className="form-group">
                        <label>Student Name:</label>
                        <input
                            type="text"
                            name="studentName"
                            value={formData.studentName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Type of Payment:</label>
                        <input
                            type="text"
                            name="paymentType"
                            value={formData.paymentType}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="modal-buttons">
                    <button type="submit" className="save-btn">Save</button>
                    {isEditing && onDelete && (
                        <button type="button" className="delete-btn" onClick={() => onDelete(membership?.id)}>Delete</button>
                    )}
                    <button type="button"
                        className="close-button close-comment-modal me-2"
                        style={{ background: 'var(--cancel-color)', color: '#000' }}
                        onClick={onRequestClose}>Cancel</button>
                </div>
            </form>
        </Modal>
    );
};

export default MembershipModal;
