import React, { useEffect, useState } from 'react'
import './ManagementPages.css'
import Loader from '../../components/Loader/Loader';
import { CircularProgress, TextField } from '@mui/material';
import { StyledTextField } from '../../components/StyledComponents/StyledInput';
import StyledButton from '../../components/StyledComponents/StyledButton';
import { createHttpService } from '../../services/httpService';
import { FROTA_BACKEND_URL } from '../../config';
import { toast } from 'react-toastify';
import { createMartialArt, deleteMartialArt, handleFetchMartialArts, updateMartialArt } from '../../services/martialArtServices';
import { MartialArt, MartialArtDetails } from '../../config/types';
import UpdateMartialArtModal from '../../components/Modals/UpdateMartialArtModal';
import OverlayLoader from '../../components/Loader/OverlayLoader';


const ManageMartialArts = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isListLoading, setIsListLoading] = useState<boolean>(false);
    const [isUpdateMartialArtModal, setIsUpdateMartialArtModal] = useState<boolean>(false);
    const [martialArtsList, setMartialArtsList] = useState<MartialArt[]>([])
    const [martialArt, setMartialArt] = useState<MartialArtDetails>({
        id: 0,
        name: '',
        duration: '',
        price: 0,
    });

    const [modalMartialArt, setModalMartialArt] = useState<MartialArtDetails>({
        id: 0,
        name: '',
        duration: '',
        price: 0
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { name, value } = event.target;
        setMartialArt((prevMartialArt) => ({ ...prevMartialArt, [name]: value }))
    }

    const getMartialArts = async () => {
        try {
            setIsListLoading(true);
            const response = await handleFetchMartialArts();
            setMartialArtsList(response);
            console.log("Martial Arts: ", response)
            setIsListLoading(false);
        } catch (error) {
            console.error("Error: ", error)
        } finally {
            setIsListLoading(false);
        }
    }

    const handleItemClick = (martialArt: MartialArtDetails) => {
        setModalMartialArt(martialArt);
        setIsUpdateMartialArtModal(true);
    }

    const handleCreateMartialArt = async () => {
        if (!martialArt.name) {
            return toast.error("Please Enter Martial Art Name.")
        }
        if (!martialArt.duration) {
            return toast.error("Please Enter Martial Art Duration.")
        }

        setIsLoading(true);
        try {
            const response = createMartialArt(martialArt);

            if ((await response).success) {
                setIsLoading(false);
                toast.success("Martial Art Created Successfully.")
                setMartialArt({
                    id: 0,
                    name: '',
                    price: 0,
                    duration: ''
                })
                getMartialArts();
            }

        } catch (error) {
            throw new Error(
                `handleCreateMartialArt(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsLoading(false);
        }
    }

    const handleUpdateMartialArt = async (updatedMartialArt: MartialArtDetails) => {
        if (!updatedMartialArt.name) {
            return toast.error("Please Enter Martial Art Name.")
        }
        if (!updatedMartialArt.duration) {
            return toast.error("Please Enter Martial Art Duration.")
        }

        setIsLoading(true);
        try {
            const response = updateMartialArt(updatedMartialArt);

            if ((await response).success) {
                setIsLoading(false);
                toast.success("Martial Art Updated Successfully.")
                setModalMartialArt({
                    id: 0,
                    name: '',
                    price: 0,
                    duration: ''
                })
                setIsUpdateMartialArtModal(false);
                getMartialArts();
            }

        } catch (error) {
            throw new Error(
                `handleUpdateMartialArt(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeleteMartialArt = async (martialArtId?: number) => {
        if (!martialArtId) {
            console.error("Not Able to Delete MartialArt of Id: ", martialArtId)
            return;
        }
        try {
            setIsLoading(true);
            const response = deleteMartialArt(martialArtId);

            if ((await response).success) {
                setIsLoading(false);
                toast.success("Martial Art Deleted Successfully.")
                setModalMartialArt({
                    id: 0,
                    name: '',
                    price: 0,
                    duration: ''
                })

                setIsUpdateMartialArtModal(false);

                getMartialArts();
            }

        } catch (error) {
            throw new Error(
                `handleDeleteMartialArt(): ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getMartialArts();
    }, [])


    return (
        <div>
            <div className='create-item-container'>
                <StyledTextField
                    id="outlined-basic"
                    label="Martial Art Name"
                    name='name'
                    type='text'
                    variant="outlined"
                    className='my-2'
                    style={{ minWidth: '250px', marginRight: 14 }}
                    value={martialArt.name}
                    onChange={handleInputChange}
                />
                <StyledTextField
                    id="outlined-basic"
                    label="Duration"
                    name='duration'
                    type='text'
                    variant="outlined"
                    className='my-2'
                    style={{ minWidth: '250px', marginRight: 14 }}
                    value={martialArt.duration}
                    onChange={handleInputChange}
                />
                <StyledTextField
                    id="outlined-basic"
                    label="Price"
                    name='price'
                    type='number'
                    variant="outlined"
                    className='my-2'
                    style={{ minWidth: '250px', marginRight: 14 }}
                    value={martialArt.price}
                    onChange={handleInputChange}
                />
                <StyledButton className="my-2"
                    style={{ padding: '6px 14px' }}
                    onClick={handleCreateMartialArt}>
                    Create Martial Art
                </StyledButton>
            </div>

            <div className="list-container">
                <h2 className='title'>MARTIAL ARTS LIST</h2>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Duration</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <OverlayLoader isLoading={isListLoading} />
                        {martialArtsList?.length > 0 ? (
                            // <tbody>
                            //     {martialArtsList.map(item => (
                            //         <tr key={item.id} onClick={() => handleItemClick(item)}>
                            //             <td>{item.id}</td>
                            //             <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{item.name}</td>
                            //             <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{item.duration}</td>
                            //             <td style={{ whiteSpace: 'nowrap', paddingLeft: '20px', paddingRight: '20px' }}>{item.price}</td>
                            //         </tr>
                            //     ))}
                            // </tbody>
                            <tbody>
                                {martialArtsList.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <tr onClick={() => handleItemClick({ ...item.details[0], name: item.name })}>
                                            <td rowSpan={item.details.length}>{item.id}</td>
                                            <td rowSpan={item.details.length}>{item.name}</td>
                                            <td>{item.details[0].duration}</td>
                                            <td>{item.details[0].price}</td>
                                        </tr>
                                        {item.details.slice(1).map((detail, index) => (
                                            <tr key={index} onClick={() => handleItemClick({ ...detail, name: item.name })}>
                                                <td>{detail.duration}</td>
                                                <td>{detail.price}</td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={7} className='no-data-message'>
                                        <p>No Martial Arts Available</p>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
                <UpdateMartialArtModal
                    isOpen={isUpdateMartialArtModal}
                    onRequestClose={() => setIsUpdateMartialArtModal(false)}
                    onSave={handleUpdateMartialArt}
                    onDelete={handleDeleteMartialArt}
                    martialArt={modalMartialArt}
                    isEditing={true}
                />
            </div>
        </div>
    )
}

export default ManageMartialArts