import * as React from 'react';
import './BarChartWithAnimation.css';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BarChart } from '@mui/x-charts/BarChart';
import { StyledCheckbox } from '../StyledComponents/StyledCheckbox';
import DataTotalBox from './DataTotalBox';

// Define the structure of a series
interface Series {
    label: string;
    data: number[];
    highlightScope: { highlight: string; fade: string };
}

const highlightScope = {
    highlight: 'item',
    fade: 'global',
} as const;

// Initialize the series data
const localChartData = [
    {
        label: 'Total',
        data: [2423, 2210, 764, 1879, 1478, 1373, 1891, 2171, 620, 1269, 724, 1707, 1188, 1879, 626, 1635, 2177, 516, 1793, 1598],
    },
    {
        label: 'Memberships',
        data: [2362, 2254, 1962, 1336, 586, 1069, 2194, 1629, 2173, 2031, 1757, 862, 2446, 910, 2430, 2300, 805, 1835, 1684, 2197],
    },
    {
        label: 'Merchandise',
        data: [1145, 1214, 975, 2266, 1768, 2341, 747, 1282, 1780, 1766, 2115, 1720, 1057, 2000, 1716, 2253, 619, 1626, 1209, 1786],
    },
    {
        label: 'PrivateClasses',
        data: [1145, 1214, 975, 2266, 1768, 2341, 747, 1282, 1780, 1766, 2115, 1720, 1057, 2000, 1716, 2253, 619, 1626, 1209, 1786],
    },
].map((s) => ({ ...s, highlightScope }));;

interface BarChartWithAnimationProps {
    labels: string[];
    chartData: {
        label: string;
        data: number[];
    }[]
}


const BarChartWithAnimation: React.FC<BarChartWithAnimationProps> = ({ labels, chartData }) => {
    // State for controlling which data series are selected (visible)
    const [visibleSeries, setVisibleSeries] = React.useState({
        Total: true,
        Memberships: true,
        PrivateClasses: true,
        Merchandise: true,
    });

    console.log('chartData: ', chartData)
    // State for slicing the number of items (months) to show in the chart
    // const [itemCount, setItemCount] = React.useState<number>(5);

    // Toggle the visibility of a series when its checkbox is clicked
    const handleSeriesVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVisibleSeries({
            ...visibleSeries,
            [event.target.name]: event.target.checked,
        });
    };

    // Filter the chart data to only include selected series
    const filteredSeries = chartData
        .filter((series) => visibleSeries[series.label as keyof typeof visibleSeries])
        .map((series) => ({
            ...series,
            data: series.data,
        }));

    // console.log(filteredSeries)
    return (
        <Box sx={{ width: '96%', margin: 'auto' }}>
            {/* Checkboxes for controlling the visibility of each series */}
            <div className="series-controls mx-auto" style={{ width: 'fit-content', }}>
                <p className='m-0 text-center'>Select Options to show in Graph</p>
                <FormControlLabel
                    control={
                        <StyledCheckbox
                            checked={visibleSeries.Total}
                            onChange={handleSeriesVisibilityChange}
                            name="Total"
                        />
                    }
                    label="Total"
                />
                <FormControlLabel
                    control={
                        <StyledCheckbox
                            checked={visibleSeries.Memberships}
                            onChange={handleSeriesVisibilityChange}
                            name="Memberships"
                        />
                    }
                    label="Memberships"
                />
                <FormControlLabel
                    control={
                        <StyledCheckbox
                            checked={visibleSeries.Merchandise}
                            onChange={handleSeriesVisibilityChange}
                            name="Merchandise"
                        />
                    }
                    label="Merchandise"
                />
                <FormControlLabel
                    control={
                        <StyledCheckbox
                            checked={visibleSeries.PrivateClasses}
                            onChange={handleSeriesVisibilityChange}
                            name="PrivateClasses"
                        />
                    }
                    label="Private Classes"
                />
            </div>

            <div className='my-2'>
                <DataTotalBox dataArray={chartData} />
            </div>

            {/* Render the BarChart with the filtered series */}
            <BarChart
                height={350}
                className="bar-chart"
                series={filteredSeries}
                skipAnimation={false}  // Set skipAnimation to false to enable animations
                xAxis={[{ data: labels, scaleType: 'band' }]}
                colors={['red', 'orange', 'blue', 'purple']}
            />
        </Box>
    );
}

export default BarChartWithAnimation;