import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

// Styled checkbox using MUI's `styled` API
export const StyledCheckbox = styled(Checkbox)({
    color: 'var(--red)', // Default color
    '&.Mui-checked': {
        color: 'var(--red)', // Checked state color
    },
    '&:hover': {
        backgroundColor: 'transparent', // To prevent default hover background
        color: 'var(--hover-color)', // Hover state color
    },
});

export default function CheckBoxDemo() {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <div>
            <StyledCheckbox checked={checked} onChange={handleChange} />
        </div>
    );
}
