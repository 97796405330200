import React, { useEffect, useState, useMemo } from 'react';
import { DateRangePicker } from 'rsuite';
import { StatisticsForYear, StatisticsLastYears } from '../config/types';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { handleFetchLastYearlyStatistics, handleFetchStatisticsByMonth, handleFetchStatisticsByYear } from '../services/statsServices';
import { getCurrentMonthDatesOnly } from '../services/baseServices';


export const useStatisticsFetch = (statisticsType: string, selectedYear: number, selectedMonth: number, dateRange: DateRange | null, selectedLastYears: number) => {
    const [responseData, setResponseData] = useState<StatisticsForYear[]>([]);
    const [yearlyResponseData, setYearlyResponseData] = useState<StatisticsLastYears[]>([]);
    const [chartLabels, setChartLabels] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                let response;
                if (statisticsType === 'monthly') {
                    response = await handleFetchStatisticsByYear(selectedYear);
                    // setChartLabels(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
                    setChartLabels(['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']);
                } else if (statisticsType === 'weekly' || statisticsType === 'daily') {
                    response = await handleFetchStatisticsByMonth(selectedYear, selectedMonth, statisticsType);
                    setChartLabels(statisticsType === 'daily' ? getCurrentMonthDatesOnly(selectedMonth, selectedYear) : Array.from({ length: response.length }, (_, i) => `Week ${i + 1}`));
                } else if (statisticsType === 'yearly') {
                    response = await handleFetchLastYearlyStatistics(selectedLastYears);
                    setChartLabels(['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']);
                    console.log(response)
                    setYearlyResponseData(response);
                    return;
                }
                setResponseData(response);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [statisticsType, selectedYear, selectedMonth, dateRange, selectedLastYears]);

    return { responseData, yearlyResponseData, chartLabels, isLoading };
};

export const useTransformData = (responseData: StatisticsForYear[], selectedDataByType: 'price' | 'quantity') => {
    return useMemo(() => {
        if (!responseData) return [];
        const membershipsData = responseData.map(item => item.memberships[selectedDataByType]);
        const merchandisesData = responseData.map(item => item.merchandises[selectedDataByType]);
        const privateClassesData = responseData.map(item => item.privateClasses[selectedDataByType]);
        const totalData = responseData.map(item => item.memberships[selectedDataByType] + item.merchandises[selectedDataByType] + item.privateClasses[selectedDataByType]);
        return [
            { label: 'Memberships', data: membershipsData },
            { label: 'Merchandise', data: merchandisesData },
            { label: 'PrivateClasses', data: privateClassesData },
            { label: 'Total', data: totalData }
        ];
    }, [responseData, selectedDataByType]);
};
