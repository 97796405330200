import './Statistics.css';
import BarChartWithAnimation from '../../components/BarChartWithAnimation/BarChartWithAnimation';
import { useEffect, useState } from 'react';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from '@mui/material';
import { StyledRadioButton } from '../../components/StyledComponents/StyledRadioButton';
import Select from 'react-select';
import { selectStyles } from '../../components/StyledComponents/SelectStyles';
import { handleFetchStatisticsByYear } from '../../services/statsServices';
import StatisticsInstance from './StatisticsInstance';
import { StyledCheckbox } from '../../components/StyledComponents/StyledCheckbox';

const Statistics: React.FC = () => {
    const [statisticsType, setStatisticsType] = useState<string>('monthly');
    const [isComparison, setIsComparison] = useState<boolean>(false);
    return (
        <div className="statistics-container">
            {/* Radio Buttons for Statistics Type */}
            <FormControl className="form-control-wrapper">
                <FormControlLabel
                    style={{ margin: 'auto' }}
                    control={
                        <StyledCheckbox
                            checked={isComparison}
                            onChange={() => setIsComparison(!isComparison)}
                            name="Stats Comparison"
                        />
                    }
                    label="Stats Comparison"
                />
                <FormLabel id="statistics-type-label" style={{ color: '#454', textAlign: 'center' }}>
                    Statistics Type:
                </FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="statistics-type-label"
                    name="statistics-type"
                    value={statisticsType}
                    onChange={(e) => setStatisticsType(e.target.value)}
                    style={{ justifyContent: 'center' }}
                >
                    <FormControlLabel value="daily" control={<StyledRadioButton />} label="Daily" />
                    <FormControlLabel value="weekly" control={<StyledRadioButton />} label="Weekly" />
                    <FormControlLabel value="monthly" control={<StyledRadioButton />} label="Monthly" />
                    <FormControlLabel value="yearly" control={<StyledRadioButton />} label="Yearly" />
                    {/* <FormControlLabel value="date-range" control={<StyledRadioButton />} label="By Date Range" /> */}
                </RadioGroup>
            </FormControl>

            <div className={`${isComparison ? 'comparison-container' : 'no-comparison-container'}`}>
                <StatisticsInstance statisticsType={statisticsType} />
                {isComparison && (<StatisticsInstance statisticsType={statisticsType} />)}
            </div>

        </div>
    );
};

export default Statistics;
