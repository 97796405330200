import { DateRange } from "rsuite/esm/DateRangePicker";
import { FROTA_BACKEND_URL } from "../config";
import { Statistics, StatisticsForYear, StatisticsLastYears } from "../config/types";
import { createHttpService } from "./httpService";


export const handleFetchStatisticsByYear = async (year: number) => {
    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.get<StatisticsForYear[]>(`/dailyReports/stats/yearly?year=${year}`);

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'handleFetchStatisticsByYear(): Unknown error');
        }

        return result.data;
    } catch (error) {
        throw new Error(
            `handleFetchStatisticsByYear(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

export const handleFetchStatisticsByMonth = async (year: number, month: number, statisticsType: string) => {
    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.get<any>(`/dailyReports/stats/monthly?year=${year}&month=${month}&type=${statisticsType}`);

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'handleFetchStatisticsByMonth(): Unknown error');
        }
        console.log(result.data)
        return result.data;
    } catch (error) {
        throw new Error(
            `handleFetchStatisticsByMonth(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}

export const handleFetchLastYearlyStatistics = async (lastYears: number) => {
    const httpService = createHttpService(FROTA_BACKEND_URL);

    try {
        const result = await httpService.get<StatisticsLastYears[]>(`/dailyReports/stats/yearlyList?difference=${lastYears - 1}`);

        if (!result.success || !result.data) {
            throw new Error(result.message ?? 'handleFetchLastYearlyStatistics(): Unknown error');
        }
        console.log(result.data)
        return result.data;
    } catch (error) {
        throw new Error(
            `handleFetchLastYearlyStatistics(): ${error instanceof Error ? error.message : 'Unknown error'}`,
        );
    }
}
