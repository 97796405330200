import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './modal.css'
import { Item, PrivateClass } from '../../config/types';

import { selectStyles } from '../StyledComponents/SelectStyles';
import { handleFetchItems } from '../../services/itemServices';
import Select from 'react-select';
import { toast } from 'react-toastify';

interface PrivateClassModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSave: (privateClass: CurrentPrivateClass) => void;
    onDelete?: (index?: number) => void;
    privateClass: CurrentPrivateClass | null;
    isEditing: boolean;
}

interface CurrentPrivateClass extends PrivateClass {
    id: number;
}

const PrivateClassModal: React.FC<PrivateClassModalProps> = ({
    isOpen,
    onRequestClose,
    onSave,
    onDelete,
    privateClass,
    isEditing
}) => {
    const [itemsList, setItemsList] = useState<Item[]>([])
    const [selectedItem, setSelectedItem] = useState({ value: '', label: '', price: 0 });

    const [formData, setFormData] = useState<CurrentPrivateClass>({
        id: 0,
        studentName: privateClass?.studentName || '',
        quantity: privateClass?.quantity || 1,
        price: privateClass?.price || 0,
    });

    const getItems = async () => {
        try {
            const response = await handleFetchItems();
            setItemsList(response);
        } catch (error) {
            console.error("Error: ", error)
        }
    }

    useEffect(() => {
        getItems();
        if (privateClass) {
            setFormData(privateClass);
        }
    }, [privateClass]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target;
        if (name === 'price') {
            setFormData({ ...formData, price: parseInt(value) });
            return;
        }
        if (name === 'quantity') {
            setFormData({ ...formData, quantity: parseInt(value) });
            return;
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData.studentName) {
            return toast.error('Please Enter a Student Name to Add.')
        }
        onSave(formData);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="AppointmentModal"
            overlayClassName="AppointmentModal-overlay"
            ariaHideApp={false}
            contentLabel={isEditing ? 'Edit Private Class' : 'Add New Private Class'}
        >
            <h2 className='text-center'>{isEditing ? 'Edit Private Class' : 'Add New Private Class'}</h2>
            <form onSubmit={handleSubmit}>
                <div className='form-group-container'>

                    <div className="form-group">
                        <label>Student Name:</label>
                        <input
                            type="text"
                            name="studentName"
                            value={formData.studentName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='form-group-container'>
                    <div className="form-group">
                        <label>Price:</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Quantity:</label>
                        <input
                            type="number"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="modal-buttons">
                    <button type="submit" className="save-btn">Save</button>
                    {isEditing && onDelete && (
                        <button type="button" className="delete-btn" onClick={() => onDelete(privateClass?.id)}>Delete</button>
                    )}
                    <button type="button"
                        className="close-button close-comment-modal me-2"
                        style={{ background: 'var(--cancel-color)', color: '#000' }}
                        onClick={onRequestClose}>Cancel</button>
                </div>
            </form>
        </Modal>
    );
};

export default PrivateClassModal;
